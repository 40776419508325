import { rgba } from "utilities/rgba";

export const primaryColor = "#51A596";

export const theme = {
  palette: {
    primary: {
      main: primaryColor,
      light: "#AAD5CE",
      dark: "#448B7E",
      dark1: "#377166",
    },
    background: {
      primaryBcg: "#AAD5CE",
      primaryDarkBcg: "#515A59",
      primaryLightBcg: "#F5F1F0",
      grayBcg: "#f8f8f8",
      disabled: "#F5F1F0",
      info: "#E8F1F8",
      success: "#EBF1EA",
      error: "#F8E3E3",
    },
    system: {
      default: "#BEA59D",
      error: "#F1786C",
      success: "#2E7D32",
      info: "#1E6FBB",
    },
    text: {
      primary: "#3F2C26",
      secondary: rgba("#3F2C26", 0.5),
      light: "#F5F1F0",
      active: "#448B7E",
      disabled: rgba("#3F2C26", 0.32),
      error: "#F1786C",
    },
    neutral: {
      white: "#fff",
      blak: "#000",
    },
  },
  textStyles: {
    body2: {
      fontSize: 14,
      lineHeight: "23px",
      fontWeight: 400,
    },
    body: {
      fontSize: 16,
      lineHeight: "26px",
      fontWeight: 400,
    },
    h3: {
      fontSize: 14,
      lineHeight: "23px",
      fontWeight: 600,
    },
    h2: {
      fontSize: 16,
      lineHeight: "26px",
      fontWeight: 600,
    },
    h1: {
      fontSize: 24,
      lineHeight: "39px",
      fontWeight: 600,
    },
  },
  space: new Array(25).fill(null).map((value, index) => index * 4),
  // those are needed here for styled-system to work correctly.
};
