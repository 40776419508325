import { Typography } from "components/Typography";
import { Flex } from "components/Flex";
import React from "react";
import styled from "styled-components";
import { ReactComponent as CheckmarkIcon } from "assets/icons/checkmark-circle.svg";
import { Box } from "components/Box";

const Root = styled.div`
  ${({ theme }) => `
    display: table;
    padding: ${theme.space[1]}px ${theme.space[2]}px;
    background-color: ${theme.palette.primary.main};
    border-radius: 4px;
  `}
`;

const StyledBox = styled(Box)`
  width: 16px;
  height: 16px;
`;

const StyledTypography = styled(Typography)`
  ${({ theme }) => `
    font-size: 12px;
    color: ${theme.palette.neutral.white};
  `}
`;

export const Badge = () => (
  <Root>
    <Flex alignItems="center">
      <StyledBox mr={1}>
        <CheckmarkIcon />
      </StyledBox>
      <StyledTypography variant="h3">done</StyledTypography>
    </Flex>
  </Root>
);
