import React, { useEffect, useState } from "react";
import { Box } from "components/Box";
import { Flex } from "components/Flex";
import { IUser } from "types/user";
import { ComboHeader } from "components/ComboHeader";
import { Menu } from "components/Menu";
import { InfoCard } from "components/InfoCard";
import { Button } from "components/Button";
import styled from "styled-components";
import { TextField } from "components/TextField";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { findPatientFromFHIRService } from "services/InitServices";

const WrapperInfo = styled.div`
  display: block;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 380px;
  margin: 0 auto;
  margin-top: 88px;
`;
const StyledButton = styled(Button)`
  width: 100%;
`;

const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const InvitePage: React.FC<IUser> = (user) => {
  const [step, setStep] = useState(0);
  const [emailValue, setEmailValue] = useState<string>("");
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [emailTouched, setEmailTouched] = useState(false);
  const [referral, setReferral] = useState("");

  const patient = sessionStorage.getItem("patient");
  const parsePatient = JSON.parse(`${patient}`);

  const navigate = useNavigate();

  const handleNextStep = () => {
    setStep((prevState) => prevState + 1);
    if (step === 1) {
      const payloadUser = { ...user, email: emailValue, createReferral: true };
      findPatientFromFHIRService(payloadUser);
    }
    if (step === 2) navigate("/dashboard/summary");
  };

  const handleError = () => {
    if (emailRegex.test(emailValue)) {
      setInvalidEmail(false);
    } else {
      setInvalidEmail(true);
    }
  };

  const handleBlur = () => {
    setEmailTouched(true);
  };

  const fetchPatientData = () => {
    findPatientFromFHIRService(user)
      .then((res) => {
        const { referralStatus } = res.data;
        setReferral(referralStatus);
      })
      .catch((err) => console.log("err", err));
  };

  useEffect(() => {
    fetchPatientData();
  }, [user]);

  useEffect(() => {
    if (referral === "pending") setStep(3);

    return () => setStep(0);
  }, [referral]);

  const generateCardInfoByStep = () => {
    switch (step) {
      case 0:
        return (
          <InfoCard
            variant="info"
            title="This patient is not using the Neura app"
            desc="To preview the data invite this person to Neura"
          />
        );
      case 1:
        return (
          <InfoCard
            variant="info"
            desc="To invite a patient to Neura, enter the patient's email address"
          />
        );
      case 2:
        return (
          <InfoCard
            variant="success"
            title="Success!"
            desc="The invitation to Neura was sent to the provided email."
          />
        );
      case 3:
        return (
          <InfoCard
            variant="info"
            title="Pending Invitation"
            desc="The invitation was sent but Patient has not yet registered in Neura app."
          />
        );
    }
  };

  useEffect(() => {
    if (step === 1) handleError();
  }, [emailValue, step]);

  return (
    <Box m={12}>
      <Flex>
        <Box mr={14}>
          <ComboHeader variant="name" label={parsePatient.name || "--"} />
        </Box>
        <ComboHeader
          variant="birth"
          label={format(new Date(parsePatient.birth), "d MMMM yyyy") || "--"}
        />
      </Flex>

      <Box mt={12}>
        <Menu disabled />
      </Box>

      <WrapperInfo>
        {generateCardInfoByStep()}
        {step === 1 && (
          <TextField
            onChange={(event) => setEmailValue(event.currentTarget.value)}
            onBlur={handleBlur}
            label="Email"
            error={invalidEmail && emailTouched ? "Invalid email address" : ""}
          />
        )}

        {step === 2 || step === 3 ? null : (
          <Box mt={8}>
            <StyledButton
              onClick={handleNextStep}
              variant="primary"
              disabled={invalidEmail}
              label="Invite Patient to Neura"
            />
          </Box>
        )}
      </WrapperInfo>
    </Box>
  );
};
