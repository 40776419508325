import React, { useEffect } from "react";
import { oauth2 as SMART } from "fhirclient";
import { useLocation } from "react-router-dom";
import { checkFhirServerService } from "services/InitServices";
import { useQueryParams } from "hooks/queryParams";

const CLIENT_ID = "2cb01724-19f6-473c-a55f-e9ae9f5c1dfe"; //process.env.REACT_APP_CLIENT_ID;

const CLIENT_SECRET =
  "eAcHTkW1PRSze08dBcXb5v3upJaeTzjMzpfxWj8eCt/XNSZNjbaey2XonH7qNIoEoqSeWtlJHyOYclHHp/fxzQ=="; //process.env.REACT_APP_CLIENT_SECRET;

export function LaunchingPage() {
  const location = useLocation();
  const queryParams = useQueryParams(location);

  const iss = queryParams.get("iss");
  const launch = queryParams.get("launch");

  useEffect(() => {
    if (!iss || !launch) {
      console.error(
        "Missing SMART EHR launch queryparams. This app should be run using SMART provider-launch sandbox."
      );
      return;
    }
    checkFhirServerService(iss);

    let ignore = false;

    if (!ignore) {
      console.log("authorize lunch component. ... ");
      SMART.authorize({
        // iss,
        target: "_top",
        redirectUri: "/home",
        clientId: CLIENT_ID, //"7c476ed1-50d5-4f17-bdf4-082316bc6ef4",
        scope: "launch openid fhirUser patient/read launch/patient",
        completeInTarget: true,
        clientSecret: CLIENT_SECRET,
        // "icxPD6PfK6wiLxoQHBNLOzXhOhdLf3rFYXOYP5GFBXdGoRVsPIt911Ke5FGwICO8NilpqRJZ8ShCMzzMZcB8aw",
      });
    }

    return () => {
      ignore = true;
    };
  }, []);

  return <p>Launching ...</p>;
}
