import axios from "axios";
import { generateCarePlanSummaryForFHIRUrl } from "constants/paths";

interface ISummary {
  startDate: string;
  description: string;
}
interface IResponse {
  summary: ISummary[];
}

export const generateCarePlanSummaryForFHIRServices = async (
  patientID: string
) => {
  const data = await axios.post<IResponse>(
    generateCarePlanSummaryForFHIRUrl,
    {
      patientID,
    },
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    }
  );
  return data;
};
