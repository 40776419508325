import React, { useState, useEffect } from "react";
import { findPatientFromFHIRService } from "services/InitServices";
import { InvitePage } from "views/Invite";
import { IUser } from "types/user";
import Spinner from "react-spinkit";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useClientContext } from "context/FihrClientContext";

interface IProps {
  dob: string;
  name: string;
}

const StyledSpinner = styled(Spinner)`
  ${({ theme }) => `
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(3);
    color: ${theme.palette.primary.main};
  `}
`;

const userPayloadNotExist = {
  firstName: "James",
  lastName: "Bond",
  birthDate: "01/01/2000",
};

const userPayloadExist = {
  firstName: "Sameer",
  lastName: "Madan",
  birthDate: "01/01/2000",
};

export const LoginPage: React.FC<IProps> = ({ dob, name }) => {
  const [loading, setLoading] = useState(true);
  const [patientIsExist, setPatientIsExist] = useState(false);
  const [user, setUser] = useState<IUser | null>(null);

  const { authToken } = useClientContext();

  const navigate = useNavigate();

  const fetchPatientData = () => {
    findPatientFromFHIRService(user!)
      .then((res) => {
        setPatientIsExist(res.data.exists);
        if (res.data.patientID) setPatientIdToStorage(res.data.patientID);
      })
      .catch((err) => console.log("err", err))
      .finally(() => setLoading(false));
  };

  const setPatientIdToStorage = (patientID: string) => {
    sessionStorage.setItem("patientId", patientID);
  };

  const patient = sessionStorage.getItem("patient");

  const generatePatientObject = () => {
    if (patient === null) return;
    const parsUserData = JSON.parse(patient);

    const splitFullName = parsUserData.name.split(" ");

    const firstName = splitFullName[0];
    const lastName = splitFullName[1];

    const payloadUser = {
      firstName,
      lastName,
      birthDate: parsUserData.birth,
    };

    setUser(payloadUser);
  };

  useEffect(() => {
    generatePatientObject();
  }, [name, dob]);

  useEffect(() => {
    if (user) {
      fetchPatientData();
    }
  }, [user, authToken]);

  const renderPage = () => {
    if (loading) {
      return <StyledSpinner name="ball-clip-rotate" />;
    }
    if (patientIsExist) {
      navigate("/dashboard/summary");
    }
    if (!patientIsExist && user) {
      return <InvitePage {...user} />;
    }
  };

  return <>{renderPage()}</>;
};
