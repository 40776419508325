import React from "react";
import { Card } from "components/Card";
import { Typography } from "components/Typography";
import styled from "styled-components";
import { Badge } from "components/Badge";
import { Box } from "components/Box";

export interface IProps {
  date: string;
  value: string;
  done: boolean;
  list?: string[];
}

const ItemList = styled(Typography)`
  ${({ theme }) => `
    position: relative;
    padding-left: 21px;
    &::before {
      content: "";
      position: absolute;
      width: 5px;
      height: 5px;
      background-color: ${theme.palette.text.brown};
      left: 8px;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 50%;
    }
  `}
`;

export const CardList: React.FC<IProps> = ({ value, list, done, date }) => {
  return (
    <>
      {list ? (
        <Card>
          {done && (
            <Box mb={4}>
              <Badge />
            </Box>
          )}
          {/* mb={4} */}
          <Box>
            <Box ml={1}>
              <Typography variant="h3" color="primary">
                {date}
              </Typography>
              <Typography variant="body" color="primary">
                {value}
              </Typography>
            </Box>
          </Box>
          {list?.map((item) => (
            <React.Fragment key={item}>
              <ItemList variant="body2" color="primary">
                {item}
              </ItemList>
            </React.Fragment>
          ))}
        </Card>
      ) : (
        <Card>
          {done && (
            <Box mb={4}>
              <Badge />
            </Box>
          )}
          <Box ml={1}>
            <Typography variant="h3" color="primary">
              {value}
            </Typography>
          </Box>
        </Card>
      )}
    </>
  );
};
