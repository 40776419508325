import { CounterCard } from "components/CounterCard";
import { Flex } from "components/Flex";
import React, { useEffect, useState } from "react";
import { Chart } from "components/Chart";
import { Box } from "components/Box";
import styled from "styled-components";
import { Typography } from "components/Typography";
import { generateAnalyticsForFHIRServices } from "services/AnalyticsServices";
import { useSelector } from "react-redux";
import { ISummary } from "types/summary";
import { useToMinutesAndSeconds } from "hooks/toHoursAndMinutes";
import Spinner from "react-spinkit";
import { toast } from "react-toastify";
import { findPatientFromFHIRService } from "services/InitServices";
import { InfoCard } from "components/InfoCard";

const StyledBox = styled(Box)`
  background-color: #fff;
  border-radius: 16px;
  width: 100%;
`;
const WrapperChart = styled(Box)`
  position: relative;
`;
const StyledTypography = styled(Typography)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
`;
const StyledSpinner = styled(Spinner)`
  ${({ theme }) => `
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(2);
    z-index: 10;
    color: ${theme.palette.primary.main};
  `}
`;
const StyledFlex = styled(Flex)`
  width: 50%;
`;
const WrapperInfoCard = styled.div`
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 350px;
`;

interface IChartData {
  name: string;
  value: number;
}

export const SummaryPage = () => {
  const [data, setData] = useState<ISummary | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const patientID = sessionStorage.getItem("patientId"); // "TSWgPqOH1gM369jMIWq5UVbn6873";

  const time = useSelector((state: any) => state.time.time);

  const createLifestyleChangesValue = () => {
    const arr: any[] = [];
    if (data?.lifestyleChanges) {
      for (const [key, value] of Object.entries(data.lifestyleChanges)) {
        arr.push({ name: key, value });
      }
    }
    return arr;
  };

  const a: string[] = [];
  const headachesData = () => {
    const headachesPerDayData: any[] = [];
    data?.dailyAttackFrequencies.map((item, index) => {
      const year = `${item[0]}`.substring(2, 4);
      const month = `${item[0]}`.substring(4, 6);
      const day = `${item[0]}`.substring(6);

      const lfc = createLifestyleChangesValue().find(
        (lfc) => lfc.name === item[0]
      );

      if (lfc?.value) {
        if (a.indexOf(lfc.value[0]) === -1) a.push(...lfc.value);
      }

      const lifestylesChangesTooltip =
        lfc?.value &&
        lfc.value.map((item: string) => {
          return item;
        });

      let life = {};

      if (lifestylesChangesTooltip && lifestylesChangesTooltip.length > 0) {
        lifestylesChangesTooltip.map((val: string) => {
          const v = { [val]: item[1] };
          life = { ...life, ...v };
        });
      }

      const obj = {
        name: `${month}/${day}/${year}`,
        Headaches: item[1],
      };

      const aaa = { ...obj, ...life };

      headachesPerDayData.push(aaa);
    });
    return headachesPerDayData;
  };

  const generateObjectForChartData = (item: any[]) => ({
    name: `${item[0]}`,
    value: item[1],
  });

  const TriggersData = () => {
    const triggers: IChartData[] = [];
    data?.triggers.map((item) => {
      triggers.push(generateObjectForChartData(item));
    });
    return triggers;
  };

  const SymptomsData = () => {
    const symptoms: IChartData[] = [];

    data?.symptoms.map((item) => {
      symptoms.push(generateObjectForChartData(item));
    });
    return symptoms;
  };

  const AurasData = () => {
    const auras: IChartData[] = [];
    data?.auras.map((item) => {
      auras.push(generateObjectForChartData(item));
    });
    return auras;
  };

  const LocationsData = () => {
    const locations: IChartData[] = [];
    data?.locations.map((item) => {
      locations.push(generateObjectForChartData(item));
    });
    return locations;
  };

  const somewhatHelpfulMedicationsData = () => {
    const somewhatHelpfulMedications: IChartData[] = [];
    data?.somewhatHelpfulMedications.map((item) => {
      somewhatHelpfulMedications.push(generateObjectForChartData(item));
    });
    return somewhatHelpfulMedications;
  };

  const somewhatHelpfulReliefsData = () => {
    const somewhatHelpfulReliefs: IChartData[] = [];
    data?.somewhatHelpfulReliefs.map((item) => {
      somewhatHelpfulReliefs.push(generateObjectForChartData(item));
    });
    return somewhatHelpfulReliefs;
  };

  const veryHelpfulMedicationsData = () => {
    const veryHelpfulMedications: IChartData[] = [];
    data?.veryHelpfulMedications.map((item) => {
      veryHelpfulMedications.push(generateObjectForChartData(item));
    });
    return veryHelpfulMedications;
  };

  const veryHelpfulReliefsData = () => {
    const veryHelpfulReliefs: IChartData[] = [];
    data?.veryHelpfulReliefs.map((item) => {
      veryHelpfulReliefs.push(generateObjectForChartData(item));
    });
    return veryHelpfulReliefs;
  };
  const sideEffectsData = () => {
    const sideEffects: IChartData[] = [];
    data?.sideEffects.map((item) => {
      sideEffects.push(generateObjectForChartData(item));
    });
    return sideEffects;
  };

  const durationValue = useToMinutesAndSeconds(data?.avgDurationSec || 0);

  const drawDurationValue = () => {
    const hours = durationValue.h;
    const minutes = durationValue.m;
    const seconds = durationValue.h;

    if (hours === 0) return `${minutes}`;
    if (minutes === 0) return `${hours}s`;
    // if (seconds === 0) return `${minutes}`;
    if (hours > 0 && minutes > 0) return `${hours}h ${minutes}m`;
  };

  useEffect(() => {
    setIsLoading(true);
    generateAnalyticsForFHIRServices(patientID, time)
      .then((res) => {
        setData(res);
      })
      .catch((err) => {
        toast.error("Something went wrong.");
      })
      .finally(() => setIsLoading(false));
  }, [time]);

  return (
    <>
      <Flex justifyContent="space-between">
        <CounterCard variant="attacks" value={data?.attackCount} />
        <CounterCard variant="duration" value={drawDurationValue()} />
        <CounterCard variant="severity" value={data?.avgSeverity} />
      </Flex>
      <>
        <StyledBox mt={8} p={6}>
          <Typography variant="h1" color="primary">
            Headaches Per Day
          </Typography>
          <WrapperChart mt={16}>
            {headachesData().length === 0 && !isLoading && (
              <StyledTypography variant="body" color="primary">
                No data available
              </StyledTypography>
            )}
            {isLoading && <StyledSpinner name="ball-clip-rotate" />}
            <Chart variant="horizontal" data={headachesData()} milestone={a} />
          </WrapperChart>
        </StyledBox>

        <Flex>
          <StyledBox mt={8} p={6} mr={8}>
            <Typography variant="h1" color="primary">
              Triggers
            </Typography>
            <WrapperChart mt={16}>
              {TriggersData().length === 0 && (
                <StyledTypography variant="body" color="primary">
                  No data available
                </StyledTypography>
              )}
              <Chart variant="vertical" data={TriggersData()} />
            </WrapperChart>
          </StyledBox>

          <StyledBox mt={8} p={6}>
            <Typography variant="h1" color="primary">
              Symptoms
            </Typography>
            <WrapperChart mt={16}>
              {SymptomsData().length === 0 && (
                <StyledTypography variant="body" color="primary">
                  No data available
                </StyledTypography>
              )}
              <Chart variant="vertical" data={SymptomsData()} />
            </WrapperChart>
          </StyledBox>
        </Flex>

        <Flex>
          <StyledBox mt={8} p={6} mr={8}>
            <Typography variant="h1" color="primary">
              Auras
            </Typography>
            <WrapperChart mt={16}>
              {AurasData().length === 0 && (
                <StyledTypography variant="body" color="primary">
                  No data available
                </StyledTypography>
              )}
              <Chart variant="vertical" data={AurasData()} />
            </WrapperChart>
          </StyledBox>

          <StyledBox mt={8} p={6}>
            <Typography variant="h1" color="primary">
              Locations
            </Typography>
            <WrapperChart mt={16}>
              {LocationsData().length === 0 && (
                <StyledTypography variant="body" color="primary">
                  No data available
                </StyledTypography>
              )}
              {isLoading && <Spinner name="ball-clip-rotate" />}
              <Chart variant="vertical" data={LocationsData()} />
            </WrapperChart>
          </StyledBox>
        </Flex>

        <Flex>
          <StyledBox mt={8} p={6} mr={8}>
            <Typography variant="h1" color="primary">
              Somewhat Helpful Medication
            </Typography>
            <WrapperChart mt={16}>
              {somewhatHelpfulMedicationsData().length === 0 && (
                <StyledTypography variant="body" color="primary">
                  No data available
                </StyledTypography>
              )}
              <Chart
                variant="vertical"
                data={somewhatHelpfulMedicationsData()}
              />
            </WrapperChart>
          </StyledBox>

          <StyledBox mt={8} p={6}>
            <Typography variant="h1" color="primary">
              Somewhat Helpful Reliefs
            </Typography>
            <WrapperChart mt={16}>
              {somewhatHelpfulReliefsData().length === 0 && (
                <StyledTypography variant="body" color="primary">
                  No data available
                </StyledTypography>
              )}
              <Chart variant="vertical" data={somewhatHelpfulReliefsData()} />
            </WrapperChart>
          </StyledBox>
        </Flex>

        <Flex>
          <StyledBox mt={8} p={6} mr={8}>
            <Typography variant="h1" color="primary">
              Very Helpful Medication
            </Typography>
            <WrapperChart mt={16}>
              {veryHelpfulMedicationsData().length === 0 && (
                <StyledTypography variant="body" color="primary">
                  No data available
                </StyledTypography>
              )}
              <Chart variant="vertical" data={veryHelpfulMedicationsData()} />
            </WrapperChart>
          </StyledBox>

          <StyledBox mt={8} p={6}>
            <Typography variant="h1" color="primary">
              Very Helpful Reliefs
            </Typography>
            <WrapperChart mt={16}>
              {veryHelpfulReliefsData().length === 0 && (
                <StyledTypography variant="body" color="primary">
                  No data available
                </StyledTypography>
              )}
              <Chart variant="vertical" data={veryHelpfulReliefsData()} />
            </WrapperChart>
          </StyledBox>
        </Flex>
        <StyledFlex>
          <StyledBox mt={8} p={6} mr={4}>
            <Typography variant="h1" color="primary">
              Side Effects
            </Typography>
            <WrapperChart mt={16}>
              {sideEffectsData().length === 0 && (
                <StyledTypography variant="body" color="primary">
                  No data available
                </StyledTypography>
              )}
              <Chart variant="vertical" data={sideEffectsData()} />
            </WrapperChart>
          </StyledBox>
        </StyledFlex>
      </>
    </>
  );
};
