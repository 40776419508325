import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Scatter,
  Legend,
  ComposedChart,
  ResponsiveContainer,
} from "recharts";
import { theme } from "config/theme";

export interface IProps {
  variant: "horizontal" | "vertical";
  data?: IData[];
  milestone?: string[];
}
interface IData {
  name: string;
  value: number;
  lfc?: number;
}

export const Chart: React.FC<IProps> = ({ variant, data, milestone }) => {
  return (
    <ResponsiveContainer
      width="100%"
      height={variant === "horizontal" ? 200 : 400}
    >
      <ComposedChart
        layout={variant === "vertical" ? "vertical" : "horizontal"}
        data={data}
        margin={{
          top: 5,
          right: 0,
          left: 50,
          bottom: 5,
        }}
        barSize={20}
      >
        <CartesianGrid strokeDasharray="3 1" />
        {variant === "horizontal" ? (
          <>
            <XAxis
              dataKey="name"
              // angle={45}
              padding={{ left: 40, right: 40 }}
            />
            <YAxis allowDecimals={false} />
            <Bar dataKey="Headaches" fill="#448B7E" />
          </>
        ) : (
          <>
            <XAxis type="number" />
            <YAxis
              hide={data?.length === 0 ? true : false}
              dataKey="name"
              type="category"
              padding={{ top: 20, bottom: 20 }}
            />
            <Bar dataKey="value" fill="#448B7E" />
          </>
        )}
        <Tooltip
          separator=" "
          formatter={(value: any, name) => {
            if (name === "value") return ` : ${value}`;
            if (name === "Headaches") return ` : ${value}`;
          }}
          labelStyle={{ color: theme.palette.text.primary, marginBottom: 5 }}
          itemStyle={{ lineHeight: 0.8 }}
        />
        {/* <Legend /> */}
        {milestone &&
          milestone.map((item) => (
            <Scatter
              key={item}
              shape="diamond"
              dataKey={item}
              fill={theme.palette.system.error}
            />
          ))}
      </ComposedChart>
    </ResponsiveContainer>
  );
};
