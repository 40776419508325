import React, { FC, useContext, useEffect, useState } from "react";
import { FhirClientContext } from "context/FihrClientContext";
import { LoginPage } from "views/Login";
import { action } from "store/provider-encounter";
import { useDispatch } from "react-redux";

interface IName {
  family: string;
  given: string[];
  prefix: string[];
  use: string;
}

interface IProviderData {
  family: string;
  given: string[];
  text: string;
  use: string;
}

export const Connected = () => {
  const [patient, setPatient] = useState({
    name: "",
    dob: "",
  });
  const ctx = useContext(FhirClientContext);
  const dispatch = useDispatch();

  const generateProviderName = (data: IProviderData): string => {
    const firstName = data.given.map((item: string) => item);
    const lastName = data.family;
    return `${firstName} ${lastName}`;
  };

  useEffect(() => {
    if (ctx.client !== null) {
      ctx.client?.patient.read().then((pat) => {
        const officialName = pat.name.find(
          (name: IName) => name.use === "official"
        );

        ctx.client?.user.read().then((res) => {
          const providerName = generateProviderName(res?.name[0]);
          dispatch(action.setProviderName(providerName));
        });

        ctx.client?.encounter.read().then((res) => {
          ctx.client.request(res.location[0].location.reference).then((res) => {
            dispatch(action.setLocationName(res.managingOrganization.display));
          });
        });

        sessionStorage.setItem("patinetID", pat.id);

        const name =
          pat?.name[0].text ||
          `${officialName.given.map((item: string) => item)} ${
            officialName.family
          }`;
        setPatient({ dob: pat?.birthDate, name });
        sessionStorage.setItem(
          "patient",
          JSON.stringify({ birth: pat?.birthDate, name })
        );
      });
    }
  }, [ctx]);

  if (ctx.client) {
    return <LoginPage dob={patient.dob} name={patient.name} />;
  }
  return <p>No FHIR client connected</p>;
};
