import axios from "axios";
import { postPhysicianNoteForFHIRUrl } from "constants/paths";

export const postPhysicianNoteForFHIRServices = async (
  patientID: string,
  note: string,
  providerName: string,
  facilityName: string
) => {
  const data = await axios.post(
    postPhysicianNoteForFHIRUrl,
    { patientID, note, providerName, facilityName },
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    }
  );
};
