import styled from "styled-components";

export const Hr = styled.div`
  ${({ theme }) => `
    display: block;
    width: 100%;
    height: 1px;
    background-color: #DCDEE1;
    margin-top: 11px;
  `}
`;
