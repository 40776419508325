import React from "react";
import { useRoutes } from "react-router-dom";

import { LaunchingPage } from "views/Launching";
import { HomePage } from "views/Home";
import { DashboardPage } from "views/Dashboard";
import { SummaryPage } from "views/Summary";
import { CarePlanPage } from "views/CarePlan";
import { ContactPage } from "views/Contact";

export const Router = () => {
  const element = useRoutes([
    {
      path: "/",
      element: <LaunchingPage />,
    },
    {
      path: "/home",
      element: <HomePage />,
    },
    {
      path: "/dashboard",
      element: <DashboardPage />,
      children: [
        {
          path: "summary",
          element: <SummaryPage />,
        },
        {
          path: "care_plan",
          element: <CarePlanPage />,
        },
        {
          path: "contact",
          element: <ContactPage />,
        },
      ],
    },
  ]);

  return element;
};
