import { configureStore } from "@reduxjs/toolkit";
import { timeSlice } from "./time-slice";
import { providerEncouner } from "./provider-encounter";

const store = configureStore({
  reducer: {
    time: timeSlice.reducer,
    names: providerEncouner.reducer,
  },
});

export default store;
