import React, { useState } from "react";
import styled from "styled-components";
import { Card } from "components/Card";
import { ReactComponent as HeadacheIcon } from "assets/icons/headache.svg";
import { ReactComponent as TimeIcon } from "assets/icons/time.svg";
import { ReactComponent as StatsChartIcon } from "assets/icons/statsChart.svg";
import { Typography } from "components/Typography";
import { Flex } from "components/Flex";
import { Box } from "components/Box";
import { ReactComponent as InfoWhiteIcon } from "assets/icons/info_white.svg";
import { ReactComponent as InfoGreenIcon } from "assets/icons/info_green.svg";

export interface IProps {
  variant: "attacks" | "duration" | "severity";
  value?: number | string;
}
interface IWrapperIcon {
  variant: string;
}

const WrapperIcon = styled.div<IWrapperIcon>`
  ${({ variant }) => `
    display: flex;
    justify-content: center;
    align-items: center;
    width: 59px;
    height: 59px;
    border-radius: 12px;
    background-color: ${variant};
    border: 1px solid ${variant};
  `}
`;
const StyledCard = styled(Card)`
  ${({ theme }) => `
    position: relative;
    width: 33.3333%;
    margin-right: ${theme.space[8]}px;
    &:last-child { 
      margin-right: 0;
    }
  `}
`;
const StyledTypography = styled(Typography)`
  font-size: 32px;
  line-height: 39px;
`;
const Hint = styled.div`
  ${({ theme }) => `
    position: absolute;
    top: ${theme.space[5]}px;
    right: ${theme.space[5]}px;
    }
  `}
`;
const HintText = styled(Card)`
  position: absolute;
  top: -80px;
  transform: translateX(-70%);
  white-space: nowrap;
  border: 1px solid #f5f1f0;
  box-shadow: 0px 4px 40px rgba(24, 24, 24, 0.0392157);
  border-radius: 16px;
  &::before {
    content: "";
    position: absolute;
    bottom: -14px;
    right: 19%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 14px 14.5px 0 14.5px;
    border-color: #ffffff transparent transparent transparent;
    box-shadow: 0px 4px 40px rgba(24, 24, 24, 0.0392157);
    z-index: 20;
  }
`;

export const CounterCard: React.FC<IProps> = ({ variant, value }) => {
  const [displayHint, setDisplayHint] = useState(false);

  const generateVariantIcon = () => {
    switch (variant) {
      case "attacks":
        return <HeadacheIcon />;
      case "duration":
        return <TimeIcon />;
      case "severity":
        return <StatsChartIcon />;
    }
  };

  const generateVariantColorIcon = (): string => {
    switch (variant) {
      case "attacks":
        return "#FBF0F3";
      case "duration":
        return "#DAE8E5";
      case "severity":
        return "#F7EAE6";
    }
  };

  const generateVariantSubheading = (): string => {
    switch (variant) {
      case "attacks":
        return "Attacks in this peroid";
      case "duration":
        return "Average duration";
      case "severity":
        return "Average max severity";
    }
  };

  const generateHintMessage = (): string => {
    switch (variant) {
      case "attacks":
        return "";
      case "duration":
        return "Excludes ongoing headaches";
      case "severity":
        return "On a scale 1-10 (10 being the worst)";
    }
  };

  const handleDisplayHint = () => {
    setDisplayHint(true);
  };

  const handleHiddenHint = () => {
    setDisplayHint(false);
  };

  return (
    <StyledCard>
      <Flex>
        <WrapperIcon variant={generateVariantColorIcon()}>
          {generateVariantIcon()}
        </WrapperIcon>
        <Box ml={4}>
          <StyledTypography variant="h1" color="primary">
            {value}
          </StyledTypography>
          <Typography variant="body2" color="secondary">
            {generateVariantSubheading()}
          </Typography>
        </Box>
      </Flex>
      {variant !== "attacks" && (
        <Hint onMouseEnter={handleDisplayHint} onMouseLeave={handleHiddenHint}>
          {displayHint ? <InfoGreenIcon /> : <InfoWhiteIcon />}
          {displayHint ? (
            <HintText>
              <Typography variant="body2" color="primary">
                {generateHintMessage()}
              </Typography>
            </HintText>
          ) : null}
        </Hint>
      )}
    </StyledCard>
  );
};
