import { Flex } from "components/Flex";
import React from "react";
import styled from "styled-components";
import { ReactComponent as ArrowLeft } from "assets/icons/arrow_left.svg";
import { ReactComponent as ArrowRight } from "assets/icons/arrow_right.svg";

interface IProps {
  perPage: number;
  currentPage: number;
  totalCount: number;
  paginate: (page: number) => void;
}
interface IItem {
  active: boolean;
}

const Item = styled.div<IItem>`
  ${({ theme, active }) => `
  position: relative;
  border: 1px solid ${theme.palette.primary.main};
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  margin: 4px;
  border-radius: 4px;
  color: ${theme.palette.text.primary};
  svg {
    width: 7px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: .7;
  }
  ${
    active &&
    `
    background-color: ${theme.palette.primary.main};
    color: #fff;
  `
  }
  `}
`;

export const Pagination: React.FC<IProps> = ({
  perPage,
  totalCount,
  currentPage,
  paginate,
}) => {
  const pageNumbers: number[] = [];

  for (let i = 1; i <= Math.ceil(totalCount / perPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <Flex>
      <Item
        active={false}
        onClick={() => {
          if (currentPage === 1) return;

          paginate(currentPage - 1);
        }}
      >
        <ArrowLeft />
      </Item>
      {pageNumbers.map((number, index) => (
        <Item key={number} active={!!(number === currentPage)}>
          <div onClick={() => paginate(number)}>{number}</div>
        </Item>
      ))}
      <Item
        active={false}
        onClick={() => {
          if (currentPage === pageNumbers[pageNumbers.length - 1]) return;
          paginate(currentPage + 1);
        }}
      >
        <ArrowRight />
      </Item>
    </Flex>
  );
};
