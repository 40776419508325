import React from "react";
import styled from "styled-components";
import { Hr } from "components/Hr";
import { NavLink, Outlet } from "react-router-dom";

interface IProps {
  disabled?: boolean;
}

const StyledNavLink = styled(NavLink)<{ disabled?: boolean }>`
  ${({ theme, disabled }) => `
    color: ${theme.palette.text.primary};
    font-size: 16px;
    margin-right: ${theme.space[10]}px;
    text-decoration: none;
    position: relative;

    ${
      disabled &&
      `
      cursor: default;
      color: rgba(63, 44, 38, 0.32);
    `
    };

    &.active {
      color: ${theme.palette.text.active};
      font-weight: 600;
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: ${theme.palette.text.active};
        bottom: -14px;
        left: 0;
      }
    }
  `}
`;

const items = [
  {
    to: "summary",
    label: "Summary",
  },
  {
    to: "care_plan",
    label: "Care plan",
  },
  {
    to: "contact",
    label: "Contact Neura",
  },
];

export const Menu: React.FC<IProps> = ({ disabled }) => {
  const handleClick = (e: any) => {
    if (disabled) e.preventDefault();
  };

  const renderMenu = () =>
    items.map((item) => (
      <StyledNavLink
        key={item.to}
        disabled={disabled}
        onClick={handleClick}
        to={item.to}
      >
        {item.label}
      </StyledNavLink>
    ));

  return (
    <div>
      <nav>
        {renderMenu()}
        {/* <StyledNavLink to="summary">Summary</StyledNavLink>
        <StyledNavLink to="care_plan">Care plan</StyledNavLink>
        <StyledNavLink to="contact">Contact Neura</StyledNavLink> */}
      </nav>
      <Hr />
    </div>
  );
};
