import axios from "axios";
import { generateAnalyticsForFHIRUrl } from "constants/paths";

export const generateAnalyticsForFHIRServices = async (
  patientID: string,
  timeBegin: number
) => {
  const data = await axios.post(
    generateAnalyticsForFHIRUrl,
    {
      patientID,
      timeBegin,
    },
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    }
  );

  return data.data;
};
