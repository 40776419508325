import React, { useState } from "react";
import styled from "styled-components";
import { Outlet, useLocation } from "react-router-dom";
import { Box } from "components/Box";
import { Flex } from "components/Flex";
import { ComboHeader } from "components/ComboHeader";
import { Menu } from "components/Menu";
import { Dropdown } from "components/Dropdown";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { action } from "store/time-slice";

const WrapperMenu = styled.div`
  width: 100%;
`;

export const DashboardPage = () => {
  const [selectLabel, setSelectLabel] = useState("");
  const location = useLocation();
  const dispatch = useDispatch();
  const time = useSelector((store: any) => store.time.time);

  const patient = sessionStorage.getItem("patient");
  const parsePatient = JSON.parse(`${patient}`);

  const onSelect = (label: string) => {
    setSelectLabel(label);
  };

  const setTimeBegin = (time: number) => {
    dispatch(action.handleChange(time));
  };

  const dropdownDefaultValue = () => {
    return items.find((item) => item.value === time)!;
  };

  const items = [
    {
      label: "last week",
      value: 0,
      onClick: () => console.log("last week"),
    },
    {
      label: "this week",
      value: 7,
      onClick: () => setTimeBegin(7),
    },
    {
      label: "last 14 days",
      value: 14,
      onClick: () => setTimeBegin(14),
    },
    {
      label: "last 30 days",
      value: 30,
      onClick: () => setTimeBegin(30),
    },
    {
      label: "last 45 days",
      value: 45,
      onClick: () => setTimeBegin(45),
    },
    {
      label: "last 60 days",
      value: 60,
      onClick: () => setTimeBegin(60),
    },
    {
      label: "last 90 days",
      value: 90,
      onClick: () => setTimeBegin(90),
    },
  ];

  return (
    <Box m={12}>
      <Flex>
        <Box mr={14}>
          <ComboHeader variant="name" label={parsePatient.name || "--"} />
        </Box>
        <ComboHeader
          variant="birth"
          label={format(new Date(parsePatient.birth), "d MMMM yyyy") || "--"}
        />
      </Flex>

      <Box mt={12}>
        <Box mb={8}>
          <Flex>
            <WrapperMenu>
              <Menu />
            </WrapperMenu>
            {location.pathname === "/dashboard/summary" && (
              <Dropdown
                items={items}
                onSelect={onSelect}
                defaultValue={dropdownDefaultValue().label}
              />
            )}
          </Flex>
        </Box>

        <Outlet />
      </Box>
    </Box>
  );
};
