import { createSlice } from "@reduxjs/toolkit";

export const providerEncouner = createSlice({
  name: "names",
  initialState: { providerName: "", locationName: "" },
  reducers: {
    setProviderName(state, action) {
      state.providerName = action.payload;
    },
    setLocationName(state, action) {
      state.locationName = action.payload;
    },
  },
});

export const action = providerEncouner.actions;
