const localHostPath = "http://127.0.0.1:5001/gezunt-c3bfe/us-central1/";
const serverPath = "https://us-central1-gezunt-c3bfe.cloudfunctions.net/";

export const checkFhirServerUrl = serverPath + "checkFhirServer";

export const authFhirTokenUrl = serverPath + "authFhirToken";

export const findPatientFromFHIRUrl = serverPath + "findPatientFromFHIR";

export const generateAnalyticsForFHIRUrl =
  serverPath + "generateAnalyticsForFHIR";

export const generateCarePlanSummaryForFHIRUrl =
  serverPath + "generateCarePlanSummaryForFHIR";

export const postPhysicianNoteForFHIRUrl =
  serverPath + "postPhysicianNoteForFHIR";
