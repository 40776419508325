import React, { useState, useEffect } from "react";
import { TextareaField } from "components/TextareaField";
import { Button } from "components/Button";
import styled from "styled-components";
import { Box } from "components/Box";
import { postPhysicianNoteForFHIRServices } from "services/PhysicianNoteServices";
import Spinner from "react-spinkit";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const Root = styled.div`
  position: relative;
  padding-bottom: 43px;
`;
const WrapperButton = styled.div`
  display: table;
  position: absolute;
  bottom: 0;
  right: 0;
`;

export const ContactPage = () => {
  const [message, setMessage] = useState("");
  const [disabledSendButton, setDisabledSendButton] = useState(true);
  const [touched, setTouched] = useState(false);
  const [sending, setSending] = useState(false);

  const data = useSelector((state: any) => state.names);

  const patientID = sessionStorage.getItem("patientId"); // "TSWgPqOH1gM369jMIWq5UVbn6873";

  const handleChangeTextarea = (
    event: React.FormEvent<HTMLTextAreaElement>
  ) => {
    setMessage(event.currentTarget.value);
  };

  const handleBlur = () => {
    setTouched(true);
  };

  const facilityName = data.locationName;
  const providerName = data.providerName;

  const handleSubmitMessage = () => {
    setSending(true);
    postPhysicianNoteForFHIRServices(
      patientID,
      message,
      providerName,
      facilityName
    )
      .then((res) => {
        setTouched(false);
        setMessage("");
        toast.success("Message has been send success.");
      })
      .catch((err) => {
        toast.error("Something went wrong.");
      })
      .finally(() => setSending(false));
  };

  useEffect(() => {
    if (!message) {
      setDisabledSendButton(true);
    } else {
      setDisabledSendButton(false);
    }
  }, [message]);

  // console.log("data from store in contact page ====>>>>", data);

  return (
    <Root>
      <Box my={8}>
        <TextareaField
          value={message}
          onChange={handleChangeTextarea}
          onBlur={handleBlur}
          error={!message && touched ? "Message can not be empty." : ""}
        />
      </Box>
      <WrapperButton>
        <Button
          onClick={handleSubmitMessage}
          disabled={disabledSendButton}
          variant="primary"
          label={sending ? "" : "Send to Neura"}
          icon={sending ? <Spinner name="ball-clip-rotate" /> : null}
        />
      </WrapperButton>
    </Root>
  );
};
