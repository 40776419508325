import React, { useState } from "react";
import styled from "styled-components";
import { useDropdown } from "hooks/dropdown";
import { Typography } from "components/Typography";
import { Card } from "components/Card";
import { Box } from "components/Box";
import { Button } from "components/Button";
import { ReactComponent as DownIcon } from "assets/icons/down.svg";
import { ReactComponent as UpIcon } from "assets/icons/upWhite.svg";

export interface IDropdown {
  items: Array<{
    label: string;
    onClick: () => any;
  }>;
  defaultValue: string;
  onSelect: (data: string) => any;
}

const Root = styled.div`
  position: relative;
`;
const WrapperChildren = styled.div`
  margin-top: -11px;
  cursor: pointer;
`;
const WrapperDropdown = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 8px;
  min-width: 180px;
  z-index: 1000;
`;
const StyledCard = styled(Card)`
  border-radius: 8px;
  box-shadow: 0px 4px 24px rgba(162, 174, 169, 0.16);
`;
const WrapperItem = styled.div`
  ${({ theme }) => `
    padding: 8px;
    cursor: pointer;
    &:hover {
      background-color: ${theme.palette.background.primaryLightBcg};
    }
  `}
`;
const StyledTypography = styled(Typography)`
  white-space: nowrap;
`;

export const Dropdown: React.FC<IDropdown> = ({
  items,
  onSelect,
  defaultValue,
}) => {
  const [label, setLabel] = useState(defaultValue);
  const { buttonRef, optionsRef, isVisible, setIsVisible } = useDropdown(false);

  const handleClick = (onClick: () => any, label: string) => () => {
    onClick();
    setIsVisible(false);
    onSelect(label);
    setLabel(label);
  };

  return (
    <Root>
      <WrapperChildren ref={buttonRef} role="button" aria-hidden="true">
        <Button
          variant={isVisible ? "primary" : "secondary"}
          label={label}
          icon={isVisible ? <UpIcon /> : <DownIcon />}
          positionIcon="right"
        />
      </WrapperChildren>
      {isVisible && (
        <WrapperDropdown ref={optionsRef}>
          <StyledCard>
            <Box>
              {items.map(({ label, onClick }) => (
                <WrapperItem
                  key={label}
                  onClick={handleClick(onClick, label)}
                  aria-hidden="true"
                  role="button"
                >
                  <StyledTypography variant="body" color="primary">
                    {label}
                  </StyledTypography>
                </WrapperItem>
              ))}
            </Box>
          </StyledCard>
        </WrapperDropdown>
      )}
    </Root>
  );
};
