import React from "react";
import styled from "styled-components";
import { ReactComponent as LogoIcon } from "assets/icons/logo.svg";
import { ReactComponent as RefreshIcon } from "assets/icons/refresh.svg";
import { Flex } from "components/Flex";

const Root = styled.div`
  ${({ theme }) => `
    width: 100%;
    height: ${theme.space[16]}px;
    background-color: ${theme.palette.neutral.white};
    padding: 16px 48px;
  `}
`;
const StyledFlex = styled(Flex)`
  height: 100%;
`;
const StyledRefreshIcon = styled(RefreshIcon)`
  cursor: pointer;
`;

export const TopHeader = () => {
  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <Root>
      <StyledFlex justifyContent="space-between" alignItems="center">
        <LogoIcon />
        <StyledRefreshIcon onClick={refreshPage} />
      </StyledFlex>
    </Root>
  );
};
