import React from "react";
import { Router } from "routes";
import { TopHeader } from "components/TopHeader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";
import { theme } from "config/theme";

const StyledContainer = styled(ToastContainer)`
  ${({ theme }) => `
    .Toastify__toast-theme--colored.Toastify__toast--success {
      background-color: #51A596;
    }
    .Toastify__toast-theme--colored.Toastify__toast--error {
      background-color: #F1786C;
    }
  `}
`;

export const App: React.FC = () => {
  return (
    <>
      <TopHeader />
      <Router />
      <StyledContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};
