import { CardList } from "components/CardList";
import { Box } from "components/Box";
import React, { useEffect, useState } from "react";
import { generateCarePlanSummaryForFHIRServices } from "services/CarePlanServices";
import Spinner from "react-spinkit";
import styled from "styled-components";
import { toast } from "react-toastify";
import { InfoCard } from "components/InfoCard";
import { Pagination } from "components/Pagination";

const WrapperInfoCard = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
const StyledSpinner = styled(Spinner)`
  ${({ theme }) => `
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(3);
    color: ${theme.palette.primary.main};
  `}
`;

interface IData {
  startDate: string;
  description: string;
}
const itemsPerPage = 20;

export const CarePlanPage = () => {
  const [data, setData] = useState<IData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  const carePlanListData = () => {
    const careplanParser = data.map((item, idx) => ({
      date: item.startDate,
      value: item.description,
      done: false,
      list: [],
    }));

    return careplanParser;
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstPost = indexOfLastItem - itemsPerPage;
  const currentItems = carePlanListData().slice(
    indexOfFirstPost,
    indexOfLastItem
  );
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  useEffect(() => {
    setIsLoading(true);
    generateCarePlanSummaryForFHIRServices("TSWgPqOH1gM369jMIWq5UVbn6873")
      .then((res) => {
        setData(res.data.summary);
      })
      .catch((err) => {
        toast.error("Something went wrong.");
      })
      .finally(() => setIsLoading(false));
  }, []);

  if (isLoading) return <StyledSpinner name="ball-clip-rotate" />;

  if (carePlanListData().length === 0 && !isLoading) {
    return (
      <WrapperInfoCard>
        <InfoCard variant="info" desc="No Care Plan Created" />
      </WrapperInfoCard>
    );
  }

  return (
    <>
      {currentItems.map((item, idx) => (
        <Box mb={6} key={idx}>
          {item.list ? (
            <CardList {...item} />
          ) : (
            <CardList date={item.date} value={item.value} done={item.done} />
          )}
        </Box>
      ))}
      <Pagination
        currentPage={currentPage}
        perPage={itemsPerPage}
        totalCount={carePlanListData().length}
        paginate={paginate}
      />
    </>
  );
};
