import axios from "axios";

import {
  checkFhirServerUrl,
  authFhirTokenUrl,
  findPatientFromFHIRUrl,
} from "constants/paths";

export const checkFhirServerService = async (iss: string) => {
  const data = await axios.post(checkFhirServerUrl, {
    serverUrl: "https://fhir.epic.com/interconnect-fhir-oauth/api/FHIR/R4", // tu jest ok
  });
  return data;
};

export const authFhirTokenService = async (
  iss: string = "https://fhir.epic.com/interconnect-fhir-oauth/api/FHIR/R4",
  token?: string
) => {
  const data = await axios.post(
    authFhirTokenUrl,
    {
      serverUrl: iss,
      token,
    },
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    }
  );
  return data;
};

interface IPayload {
  firstName: string;
  lastName: string;
  birthDate: string;
  email?: string;
  createReferral?: boolean;
}
interface IFindPatientFromFHIRServiceResposponse {
  exists: boolean;
  patientID: string;
  referralStatus: string;
}

export const findPatientFromFHIRService = async (
  payload: IPayload,
  token?: string
) => {
  const data = await axios.post<IFindPatientFromFHIRServiceResposponse>(
    findPatientFromFHIRUrl,
    payload,
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    }
  );
  return data;
};
