import React, { useState, useEffect, useContext } from "react";
import { oauth2 as SMART } from "fhirclient";
import Client from "fhirclient/lib/Client";
import { authFhirTokenService } from "services/InitServices";
import { useLocalStorage } from "hooks/loaclStorage";

interface IProps {
  children: React.ReactNode;
}

interface IClientContext {
  client: Client | null;
  authToken: string;
  setClient: (client: Client) => void;
}

const clientContext: IClientContext = {
  client: null,
  authToken: "",
  setClient: (client: Client) => {
    clientContext.client = client;
  },
};

export const FhirClientContext = React.createContext(clientContext);

export const FhirClient: React.FC<IProps> = ({ children }) => {
  const [client, setClient] = useState<Client | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [authToken, setAuthToken] = useState("");

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      SMART.ready().then(
        (res) => {
          console.log("res from context ", res);
          const idToken = res.getIdToken();
          authFhirTokenService(idToken?.iss, res.state.tokenResponse?.id_token)
            .then((token) => {
              setClient(res);
              const { data } = token;
              localStorage.setItem("authToken", data);
              setAuthToken(data);
              console.log("wszystko jest ok");
            })
            .catch((error) => {
              setAuthToken(null);
              setError(error);
            });
        },
        (err) => {
          console.log("error 2");
          setError(err);
        }
      );
    }

    return () => {
      ignore = true;
    };
  }, []);

  console.log("error -> ", error);
  console.log("client -> ", client);

  if (error) return <pre>{error.stack}</pre>;
  if (client)
    return (
      <FhirClientContext.Provider value={{ client, authToken, setClient }}>
        {children}
      </FhirClientContext.Provider>
    );

  return <p>Authorizing... </p>;
};

export const useClientContext = () => {
  const context = useContext(FhirClientContext);
  if (!context) {
    throw new Error("useClientContext must be inside a FhirClient with value.");
  }
  return context;
};
