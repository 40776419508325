import { createSlice } from "@reduxjs/toolkit";

export const timeSlice = createSlice({
  name: "time",
  initialState: { time: 14 },
  reducers: {
    handleChange(state, action) {
      state.time = action.payload;
    },
  },
});

export const action = timeSlice.actions;
